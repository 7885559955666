@import "functions/unicode-icon";
@import "includes/libs";
@import "includes/base";
@import "includes/slick";
@import "includes/slick_custom";
@import "includes/bootstrap-select";
//@import "includes/_focusPoint";

//Import Modules / Plugins
@import "modules/tabBar";
@import "modules/stage";
@import "modules/checkList";
@import "modules/event_listView";
@import "modules/event_info";
@import "modules/event_listFilter";
@import "modules/event_listOrder";
@import "modules/eventTeaser";
@import "modules/listTeaser";
@import "modules/deeplink";
@import "modules/fbteaser";
@import "modules/textBox";
@import "modules/galleryNav";
@import "modules/ratioIbe";

@import "includes/usercentrics";

header {
  position: relative;
  background-color: $default-company-complementary-color;
  height: 55px;
  @include media-breakpoint-up(lg) {
    height: 130px;
  }
  @import "includes/header";
}

main {
  min-height: 1200px;
  background: rgb(241, 241, 241); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(235, 235, 235) 0%, rgba(255, 255, 255, 1) 250px); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgb(235, 235, 235) 0%, rgba(255, 255, 255, 1) 250px); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(235, 235, 235) 0%, rgba(255, 255, 255, 1) 250px); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0); /* IE6-9 */

  &.detailPage {
    background: -moz-linear-gradient(top, rgb(205, 205, 205) 0%, rgba(255, 255, 255, 1) 700px); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(205, 205, 205) 0%, rgba(255, 255, 255, 1) 700px); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgb(205, 205, 205) 0%, rgba(255, 255, 255, 1) 700px); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
  }
  
  @import "includes/main";
}

footer {
  position: relative;
  background: rgb(241, 241, 241); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(235, 235, 235) 0%, rgba(255, 255, 255, 1) 80%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgb(241, 241, 241) 0%, rgba(255, 255, 255, 1) 80%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(241, 241, 241) 0%, rgba(255, 255, 255, 1) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
  @import "includes/footer";
}

@import "includes/develop";
@import "../html/js/mediaelement/mediaelementplayer.css";
