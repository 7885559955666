#tabBar {
  position: relative;
  .container {
    padding: 0;
  }
  overflow: hidden;
  margin: 50px 20px;

  .doSwipe {
    position: absolute;
    right: 0;
    top: 15px;
    height: 35px;
    width: 35px;
    background: url(../html/images/touch_icon.svg);
    background-repeat: no-repeat;
    z-index: 888;
    animation-name: doSwipeAni;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes doSwipeAni {
    0% {
      right: 0;
    }
    100% {
      right: 50px;
    }
  }

  .tabSlider {
    position: relative;
    overflow-y: hidden;
    overflow-x: scroll;
    touch-action: manipulation;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      overflow-x: hidden;
    }

    nav {
      position: relative;
      width: 1000px;
      .nav-tabs {
        border: none;
        .nav-item {
          position: relative;
          z-index: 100;
          background: none;
          border-color: $default-company-color;
          border-bottom: none;
          color: $default-company-color;
          text-align: center;
          border-radius: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          line-height: 35px;
          width: calc((100% / 6) - 20px);
          margin-right: 24px;
          transition: none;
          @include media-breakpoint-up(xl) {
            line-height: 50px;
          }

          &.active {
          }

          &:last-child {
            margin-right: 0;
          }
        }
        .nav-link {
          &.active {
            border-bottom: 4px solid rgba(255, 255, 255, 1);
          }
        }
        &:AFTER {
          position: absolute;
          z-index: 80;
          content: '.';
          left: 0;
          bottom: 0;
          display: block;
          height: 1px;
          width: 100%;
          border-bottom: 1px solid $default-company-color;
        }
      }
      @include media-breakpoint-up(xl) {
        width: 1200px;
      }
    }
  }
}
.overviewtab .inner {
  margin-bottom: 20px;
}
.tab-content
{
  width:100%;

  .tab-pane
  {
    h2
    {
      margin-bottom:23px;
    }
    .inner
    {
      h2,
      .h2
      {
        padding-left:0;
      }
    }
    margin-bottom: 50px;
  }
}
//Reiseverlauf
#itinerary
{
  @include media-breakpoint-up(md) {
    padding-right: 0;
  }
  .acc_head
  {
    a
    {
      font-size: 22px;
      color: $default-company-color;
      display: block;
      position: relative;
      padding-right:35px;
      &:after
      {
        content: "\f077";
        font-family: $fa-font-family-light;
        @extend .fal;
        transition: all 0.20s ease;
        position: absolute;
        right:10px;
        font-size: 16px;
        top:50%;
        margin-top:-8px;
      }
      &.collapsed
      {
        &:after
        {
          transform: rotate(180deg);
        }
      }
    }
  }
  .acc_content
  {
    padding-top:30px;
    padding-bottom:30px;
    .icol
    {
      margin-bottom:20px;
    }
  }
  .acc_entry
  {
    padding-top:20px;
    padding-bottom:15px;
    border-bottom: 1px solid $default-dark-light-color;
  }

}

//hotels
.hotelstab
{
  .heading
  {
    background-color: #f5f5f5;
    //background: lime;
    margin:0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .leftcol,
    .rightcol
    {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .leftcol
    {
      padding-left: 10px;
      @include media-breakpoint-up(md) {
        padding-left: 25px;
      }
    }
    .rightcol
    {
      border-left: 1px solid $default-company-complementary-color;
      padding-left:5px;
      padding-right:5px;
      &.open
      {
        background-color: $default-dark-light-color;
      }
    }
    .info
    {
      display: none;
      @include media-breakpoint-up(md){
        display: block;
        color: $default-company-color;
      }
    }
  }
  .hotelbody
  {
    background-color: $default-dark-light-color;
    margin-bottom:25px;

    .mobile_iteaser
    {
      display: flex;
      @include media-breakpoint-up(md){
        display: none;
      }
      div
      {
        width:100%;
        height:100%;
        background-position: center center;
        background-size: cover;
      }
    }
    table
    {
      width:100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid $default-company-complementary-color;
      border-top: none;
      border-left: none;
      thead th
      {
        text-align: right;
        border-top: none;
      }
      th,
      td
      {
        padding:10px;
        border: 1px solid $default-company-complementary-color;
        //border: 1px solid deeppink;
        border-left: none;
      }
      tr:last-of-type
      {
        td,
        th
        {
          border-bottom: none;
        }
      }
      th
      {
        font-family: $fontbold;
        font-weight: bold;
      }
      td
      {
        text-align: right;
      }
      &.dtab
      {
        display: none;
        @include media-breakpoint-up(lg){
          display: table;
        }
        .available {
          color: #D3D3D3;
          font-size: 9px;
          line-height: 24px;
        }
      }
      &.mtab {
        display: table;
        thead th {
          i {
            display: block;
            text-align: center;
          }
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }
        th {
          font-size: 12px;
        }
      }
    }
    .btn.btn-secondary.next
    {
      margin-top:15px;
      margin-bottom:15px;
    }
    .row.no-gutters
    {
      border-top: 1px solid $default-company-complementary-color;
      background-color: #f0f0f0;
    }
  }
  .int_service
  {
    text-align: center;
    @include media-breakpoint-up(md){
      text-align: right;
    }
    p
    {
      display: block;
      color: $default-company-color;
      padding: 15px 0 5px 0;
      font-size: 16px;

      @include media-breakpoint-up(md){
        display: none;
      }
    };
  }
  .infotoggle
  {
    text-align: center;
    cursor: pointer;
    color: $default-company-color;
    p{
      margin:0;
      font-size: 13px;
      color: inherit;
      @include media-breakpoint-down(md) {
        line-height: 20px;
      }
    }
    i{
      display: none;
      color: inherit;
    }
    &.closed{
      i.fa-plus{
        display: inline;
      }
    }
    &.open{
      i.fa-minus{
        display: block;
        margin-top: 4px;
      }
    }
  }

  .toggleCont.closed{
    display: none;
  }
  .toggleCont.open{
    display: block;
  }
  .row:first-of-type{
    .col-lg-9
    {
      padding-right:30px;
      padding-left:30px;
      padding-top:20px;
      padding-bottom:20px;
      @include media-breakpoint-up(md){
        padding-right:10px;
      }
    }
    .col-lg-3
    {
      padding-top:20px;
      padding-bottom:20px;
      @include media-breakpoint-up(md){
        padding-right:30px;
      }
      img
      {
        margin-bottom:1px;
      }
    }
  }
  .iteaser
  {
    background-position: center center;
    background-size: cover;
    display: none;
    @include media-breakpoint-up(md){
      display: flex;
    }
  }
}
//photos
.img_tile,
.img_tile_big {
  background-position: center center;
  background-size: cover;
}
.img_tile {
  height: 300px;
  margin-bottom: 10px;
  @include media-breakpoint-up(lg){
    height: 300px;
    margin-bottom: 20px;
  }
  @include media-breakpoint-up(xl){
    height: 350px;
  }
}
.img_tile_big {
  height: 300px;
  @include media-breakpoint-up(lg){
    height: 620px;
  }
  @include media-breakpoint-up(xl){
    height: 720px;
  }
}

//extras
.extrastab
{

  .extratile
  {
    border: 1px solid $default-company-color;
    height:184px;
    border-radius: 10px;
    padding:20px;
    position: relative;
    margin-bottom:20px;
    display: block;
    .ctawrap
    {
      text-align: right;
      position: absolute;
      bottom:20px;
      left:0;
      width:100%;
      padding-right:20px;
      a,
      > span
      {
        color: $default-company-color;
        text-decoration: underline;
        &:hover
        {
          text-decoration: none;
        }
      }
    }
    .hidden
    {
      display: none;
    }
  }
}
