.listFilter {
  padding: 30px 0;
  @include media-breakpoint-up(lg) {
    padding: 60px 0;
  }
  .locWrapper {
    margin-bottom: 20px;
  }
  .dropdown-menu {
    list-style: none;
    font-size: 22px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  .btn-light {
    font-size: 22px;
    border: 1px solid $default-text-color;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+50,f6f6f6+99 */
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover, #ffffff 50%, #f6f6f6 99%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, #ffffff 50%, #f6f6f6 99%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, #ffffff 50%, #f6f6f6 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f6f6', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  }
  .dropdown-toggle::after {
    border: none;
    font-family: $fa-font-family;
    @extend .fa;
    content: "\f078";
    vertical-align: auto;
    font-size: 16px;
  }
  .btn {
    padding-right: 40px;
    padding-left: 20px;
  }

  .checkWrapper {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    font-size: 0;
    .form-check-inline {
      font-size: 16px;
      padding: 0 10px;
      margin: 0;
      white-space: nowrap;
    }
    .form-check {
      position: relative;
      &-input {
        position: absolute;
        top: 0;
        left: 0;
      }
      width: 33.33333%;
      label:hover {
        color: $default-company-color;
      }
    }
    @include media-breakpoint-up(lg) {
      text-align: center;
      .form-check {
        width: auto;
      }
    }
    .form-check-inline:last-of-type {
      //margin-right: 0;
    }
  }
}