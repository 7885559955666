#listviewTiles,
#listviewList {
  margin-bottom: 40px;
}

#listviewTiles {
  .eventTeaser ul.eventType li {
    display: none;
    &.active {
      display: inline-block;
    }
  }
}

#listviewList {
  .listTeaser ul.eventType li {
    @include media-breakpoint-down(md) {
      display: none;
    }
    &.active {
      display: inline-block;
    }
  }
  :first-child > .listTeaser {
    border-top: 1px solid $default-dark-light-color;
  }
}