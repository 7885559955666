.stage {
  position: relative;
  margin-bottom: 35px;
  @include media-breakpoint-up(md) {
    margin-bottom: 75px;
  }
  .wave {
    .item {
      position: absolute;
      z-index: 150;
      width: 100%;
      height: 50px
    }
    &__top {
      img {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
      top: 200px;
      @include media-breakpoint-up(sm) {
        top: 300px;
      }
    }
    &__bottom {
      img {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
      top: 249px;
      @include media-breakpoint-up(sm) {
        top: 349px;
      }
    }
  }
  .stageItem {
    &__Visual {
      height: 250px;
      @include media-breakpoint-up(sm) {
        height: 350px;
      }
      @include media-breakpoint-up(xl) {
        height: 350px;
      }
    }
  }
}