.inner {
  position: relative;
  z-index: 800;
  .interair_logo {
    z-index: 999;
    display: block;
    width: 95px;
    background: $default-company-color;
    padding: 15px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include media-breakpoint-up(lg) {
      width: 170px;
      padding: 50px 0;
    }
  }
  .mainNavDesktop,
  .subNavDesktop
  {
    padding-top:36px
  }
  .mainNavDesktop ul,
  .subNavDesktop ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      line-height: 55px;
    }
  }

  .mainNavDesktop ul {
    text-align: center;
    li {
      font-size: 18px;
      margin-right: 15px;
      a {
        position: relative;
        color: $default-company-color;
        border-bottom: 2px solid $white;
        &.active,
        &:hover,
        &:focus {
          border-bottom: 2px solid $default-company-color;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      @include media-breakpoint-up(xl) {
        font-size: 30px;
        margin-right: 30px;
      }
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  .subNavDesktop ul {
    text-align: right;
    li {
      position: relative;
      font-size: 16px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      &.open > a {
        color: $default-company-color;
      }
      @include media-breakpoint-up(xl) {
        font-size: 18px;
        margin-right: 25px;
      }
      ul {
        position: absolute;
        display: block;
        text-align: left;
        font-size: 16px;
        background: rgba(255, 255, 255, 0.9);
        padding: 20px;
        top: 50px;
        left: -20px;
        -webkit-box-shadow: 0px 30px 90px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 30px 90px 0px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        li {
          display: block;
          line-height: 30px;
          margin: 0;
          margin-bottom: 10px;
          white-space: nowrap;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .mobMenu {
    line-height: 55px;
    width: 55px;
    height: 55px;
    max-width:100%;
    font-size: 20px;
    display: block;
    text-align: right;
    padding-right:10px;
    float:right;
    color: $default-company-color;
    z-index: 800;
    cursor: pointer;
    &:BEFORE {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f0c9";
    }
  }
}

#menu_navmobile {
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: $default-company-color;
  z-index: 666;
  width: 100%;
  padding-top: 55px;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
    }
  }
  ul li.open ul {
    display: block !important;
  }
  .mainNavMobile ul,
  .subNavMobile ul {
    margin-left: -10px;
    margin-right: -10px;
    li {
      position: relative;
      line-height: 60px;
      border-bottom: 1px solid $white;
      margin: 0;
      a {
        color: #fff;
        font-size: 22px;
        padding: 0 10px;
        display: block;
      }
      &.haschild:AFTER {
        position: absolute;
        font-family: $fa-font-family;
        @extend .fa;
        content: "\f067";
        right: 20px;
        top: 0px;
        line-height: 60px;
        color: $white;
      }
      &.haschild.open:AFTER,
      &.haschild.hover:AFTER {
        content: "\f068";
      }
      ul {
        background: #007abe;
        li {
          a {
            padding-left: 40px;
          }
          &:first-child {
            border-top: 1px solid $white;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
  .metaNavMobile ul {
    li {
      line-height: 50px;
      margin: 0;
      a {
        color: $white;
        font-size: 16px;
        padding: 0;
        display: block;
      }
    }
  }
}

&.mobNavActive {
  @include media-breakpoint-down(lg) {
    .mobMenu {
      &:BEFORE {
        color: $white;
        content: '\f00d';
      }
    }
  }
}

.hideOnStart {
  display: none;
}

.wave {
  z-index: 100;
  width: 100%;
  height: 50px;
  position: absolute;
  top: 128px;
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}
