.eventTeaser {
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  .teaser {
    &__flag {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 200;
      font-size: 20px;
      padding: 5px 20px;
      background: $default-company-color;
      color: $default-company-complementary-color;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &__image {
      width: 100%;
      height: 200px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      .img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-position: center center;
        width: 100%;
        height: 200px;
        transition: all 0.8s ease;
      }
    }
    &__content {
      border: 1px solid $default-company-color;
      border-top: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px 20px 20px 20px;
      &__date {
      }
      &__label {
      }
      &__text {
      }
      &__cta {
        text-align: right;
        margin-top: 20px;
      }
    }
  }
  &:hover {
    .teaser__image .img {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}