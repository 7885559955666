.listTeaser {
  position: relative;
  line-height: 30px;
  border-top: 1px solid $default-dark-light-color;
  padding: 5px 0;

  @include media-breakpoint-up(lg) {
    line-height: 65px;
    padding: 0;
  }

  .teaser {
    &__content {
      &__inner {
        width: 85%;
        display: inline-block;
        @include media-breakpoint-up(lg) {
          display: inline-block;
          width: 85%;
        }
      }
      &__date {
        @include media-breakpoint-up(lg) {
          width: 10%;
          display: inline-block;
          order: 2;
        }
      }
      &__label {
        a.h4 {
          font-size: 18px;
          @include media-breakpoint-up(lg) {
            font-size: 22px;
          }
        }
        @include media-breakpoint-up(lg) {
          width: 40%;
          display: inline-block;
          float: left;
        }
      }
      &__tags {
        display: inline-block;
      }
      &__cta {
        display: inline-block;
        float: right;
        width: 15%;
        text-align: right;
        span {
          width: 100%;
        }
        @include media-breakpoint-down(lg) {
          width: 10%;
          span {
            width: auto;
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            height: 40px;
            &.next {
              padding-right: 20px;
            }
            &.back {
              padding-left: 20px;
            }
            span {
              display: none;

            }
          }
        }
      }
    }
  }
}