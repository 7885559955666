.listOrder {
  position: relative;
  line-height: 36px;
  text-align: right;
  @include media-breakpoint-up(lg) {
    line-height: 52px;
    margin-bottom: 20px;
  }
  .element {
    display: inline-block;
    margin-left: 20px;
  }
  &__name,
  &__date {
    &.desc:BEFORE {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f160";
      vertical-align: auto;
      font-size: 18px;
    }
    &.asc:BEFORE {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f161";
      vertical-align: auto;
      font-size: 18px;
    }
  }
  &__view {
    display: inline-block;
    .viewTile:BEFORE {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f00a";
      vertical-align: auto;
      font-size: 18px;
    }
    .viewList:BEFORE {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f039";
      vertical-align: auto;
      font-size: 18px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
  a:hover,
  a.active {
    color: $default-company-color!important;
  }
}