@import "../vendors/bootstrap";
@import "../includes/libs";
@import "font";
@import "focusPoint";
@import "bootstrap-datepicker";

@include media-breakpoint-down(sm) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  //.row
  //{
  //  margin-left:-20px;
  //  margin-right:-20px;
  //}
}
iframe
{
  max-width:100%;
}
.container .container {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

body {
  color: $default-text-color;

  &.dev {
    .container {
      background-color: transparentize(aqua, 0.5);
    }

    .container-fluid {
      background-color: transparentize(aquamarine, 0.5);
    }

    div, form, table {
      outline: 1px solid #783b8e !important;
    }

    address, blockquote, h1, h2, h3, h4, h5, h6, p, pre {
      outline: 1px solid deeppink !important;
    }
  }
}

h1, .h1 {
  color: $default-company-color;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 8px;
  @include media-breakpoint-up(sm) {
    font-size: 40px;
    line-height: 44px;
  }
}

h2, .h2 {
  color: $default-company-color;
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 8px;
  @include media-breakpoint-up(sm) {
    font-size: 34px;
    line-height: 44px;
  }
}

h3, .h3 {
  color: $default-company-color;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 30px;
  @include media-breakpoint-up(sm) {
    font-size: 30px;
    line-height: 34px;
  }
}

h4, .h4 {
  color: $default-company-color;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
  @include media-breakpoint-up(sm) {
    font-size: 22px;
    line-height: 26px;
  }
}

h5, .h5 {
  color: $default-company-color;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;

  &.light {
    font-weight: 400;
  }
}

p {
  color: $default-text-color;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 11px;

  &.teaser {
    font-size: 16px;
    line-height: 23px;
    @include media-breakpoint-up(sm) {
      line-height: 25px;
    }
  }

  &.intro {
    font-size: 16px;
    line-height: 28px;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
      line-height: 34px;
    }
  }
}

a {
  transition: all 0.2s;
  text-decoration: none;
  display: inline-block;
  &:hover, &:visited, &:focus {
    text-decoration: none;
  }

  &.active {
    color: $default-company-color;
  }
}

li {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
}

.date {
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 0;
}
.stage + #maincontent > .pt-6 {
  padding-top: 0 !important;
}
.content {
  ul, ol {
    position: relative;

    li {
      line-height: 50px;
      margin: 0;

      &:AFTER {
        position: absolute;
        content: '.';
        left: 0;
        display: block;
        height: 1px;
        width: 100%;
        text-indent: -9999px;
        border-top: 1px solid #d9d9d9;
      }

      &:last-child:AFTER {
        content: none;
      }
    }
  }
}

.editor a {
  text-decoration: underline;
}

.btn {
  position: relative;
  text-align: center;
  padding: 8px 30px;
  border: none;

  &.big {
    padding: 12px 40px;
    min-width: 180px;
    display: inline-block;
    &:after {
      line-height: 50px !important;
      display: inline-block;
    }
  }

  &.btn-primary {
    color: $default-company-complementary-color !important;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($default-company-color, 2%);
    }
  }

  &.btn-default {
    color: $default-company-complementary-color !important;
    background-color: $default-company-color-secondary;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($default-company-color-secondary, 2%);
    }
  }

  &.btn-secondary {
    color: $default-company-complementary-color !important;

    &:hover,
    &:focus,
    &:active {
      background-color: lighten($default-company-color-secondary, 2%);
    }
  }

  &.btn-light {
    border: 1px solid $default-dark-color;
    color: $default-dark-color !important;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#fff, 2%);
    }
  }
  &.btn-primary-light {
    border: 1px solid $default-company-color;
    color: $default-company-color !important;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#fff, 2%);
    }
  }
  &.btn-secondary-light {
    border: 1px solid $default-company-color-secondary;
    color: $default-company-color-secondary !important;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#fff, 2%);
    }
  }

  &.next {
    padding-right: 40px;

    &:AFTER {
      position: absolute;
      right: 20px;
      @extend .fal;
      content: "\f105";
      transition: all .3s;
      top: 1px;
      line-height: 40px;
    }

    &:hover:AFTER {
      right: 15px;
    }
  }

  &.back {
    padding-left: 40px;

    &:BEFORE {
      position: absolute;
      left: 20px;
      @extend .fal;
      content: "\f104";
      transition: all .3s;
      top: 1px;
      line-height: 40px;
    }

    &:hover:BEFORE {
      left: 15px;
    }
  }

  &.noLabel {
    height: 40px;

    &.next {
      padding-right: 20px;
    }

    &.back {
      padding-left: 20px;
    }

    span {
      display: none;
    }
  }

  &:hover {
    transition: all .3s;
  }
  &.underlined
  {
    border-bottom: 1px solid $default-text-color;
    border-radius: 0;
    padding: 8px 30px 2px 0;
    &:after
    {
      right: 10px;
    }
    &:hover
    {
      &:after
      {
        right:0;
      }
    }
  }
}

.form-control {
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: rgba($default-text-color, 0.2);
  }
}

.form-group {
  label {
    cursor: pointer;
  }
}

input[type=checkbox],
input[type=radio] {
  display: inline;
  position: absolute;
  opacity: 0;

  + label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    padding-left: 30px;

    a {
      text-decoration: underline;
    }

    &.text-danger {
      &, & span, & p {
        @extend .text-danger;
      }
    }
  }

  + label:before {
    display: inline-block;
    font-family: $fa-font-family-light;
    @extend .fal;
    font-size: 1.2rem;
    content: "\f0c8";
    letter-spacing: 5px;
    position: absolute;
    left: 0;
    top: 3px;
  }

  &:checked + label {
    &, & span, & p {
      color: $default-company-color;
    }

    & + .hide-on-checked {
      display: none;
    }

    &:before {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f14a";
    }
  }

  &:checked + label.text-danger {
    &, & span, & p {
      color: $default-company-color !important;
    }
  }
}

input[type="checkbox"] {
  + label {
    p {
      vertical-align: top;
    }
  }
  &.small-imprint + label {
    font-size: 14px;
    padding-top: 1px;
  }
}

input[type="radio"] {
  + label {
    p {
      vertical-align: middle;
    }
  }
}

input[type=radio] {
  + label:before {
    content: "\f111";
  }

  &:checked + label:before {
    content: "\f192";
  }
}

.color {
  &__black {
    color: #000000;
  }
  &__red {
    color: #ff0000;
  }

  &__orange {
    color: #ff6d00;
  }

  &__magenta {
    color: $default-company-color-secondary;
  }

  &__green {
    color: #00c853;
  }

  &__blue {
    color: $default-company-color;
  }

  &__default {
    color: $default-text-color;
  }
}

#modal {
  background-color: rgba(255, 255, 255, 0.7);

  .modal-content {
    border: 1px solid $default-company-color;
  }

  .modal-date {
    font-size: 14px;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;

    .close {
      position: relative;
      top: -3px;
      color: $default-company-color;
    }
  }

  .modal-title {
    padding: 0 1rem;
  }

  .modal-footer {
    justify-content: space-around;
    border-top: none;
  }
}

.pointer {
  cursor: pointer;
}

.ls-none {
  list-style: none;
}

.form-error {
  border-color: $state-danger-text;
  color: $state-danger-text;
}

.form-message {
  font-size: $font-size-base;
  padding-top: 5px;
  font-weight: bold;

  &:last-of-type {
    padding-bottom: 5px;
  }
}

.bottom-edge {
  border-bottom: 1px solid $gray-500;
}

.form-optional {
  display: inline-block;
  //padding-left: .5em;
  font-size: 14px;
  color: $gray-500 !important;
}

.contentSpacing {
  &Top {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }
  &Bottom {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }
}

.btn-collapse {
  padding: 9px 16px;
}

.align-left {
  text-align: left !important;
}
