iframe{
  &.ratioIbe{
    width: 100%;
    min-height:1200px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    border: none;
  }
}
