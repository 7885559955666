.abs {
  h1,.h1 {
    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }
}
.textBox {
  @include media-breakpoint-down(sm) {
    a.tbImageLink {
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-bottom: $grid-gutter-width;
    }
  }
  @include media-breakpoint-up(sm) {
    img {
      text-align: center;
      margin-bottom: $grid-gutter-width;
    }
    a.tbImageLink {
      display: inline-block;
      text-align: center;
      margin-bottom: $grid-gutter-width;
    }
  }
  .gabRight {
    padding-right: 20px;
  }
  .gabLeft {
    padding-left: 20px;
  }
}