.deeplink {
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 40px;
  .teaser {
    &__image {
      width: 100%;
      height: 200px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      background-color: $default-company-color;
      .img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 200px;
        transition: all 500ms ease;
      }
    }
    &__content {
      border: 1px solid $default-company-color;
      border-top: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 20px 20px 20px 20px;
    }
  }
  &:hover {
    .teaser__image .img {
      opacity: 0.8;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
}