.event__info {
  h1 {
    margin-bottom: 20px;
  }
  .btn {
    font-size: 18px;
    margin-top: 20px;
  }
  order: 1;
  @include media-breakpoint-up(lg) {
    order: 2;
  }
}