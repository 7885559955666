.event__checkList {
  order: 2;
  @include media-breakpoint-up(lg) {
    order: 1;
  }
  ul.icnList {
    padding: 0;
    li {
      font-size: 16px;
      position:relative;
      list-style-type: none;
      white-space: nowrap;
      user-select: none;
      i {
        width: 40px;
        text-align: center;
        &.fas {
          font-size: 8px;
        }
      }
      & > :not(a) {
        cursor: default;
      }
      > a {
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
        &:after {
          content: unquote("'#{$fa-var-chevron-right}'");
          font-family: $fa-font-family-solid;
          position: absolute;
          right: 5px;
          top: calc(50% + 2px);
          transform: translateY(-50%);
        }
      }
    }
  }
  .cta {
    text-align: center;
    margin-bottom: 20px;
  }
}