ul.eventType {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    margin: 0 5px 5px 0;
    font-size: 14px;
    line-height: 22px;
    background: #f0f0f0;
    color: $white;
    &.active {
      background: $default-company-color;
    }
  }
}


.juiceBox {
  margin-bottom: 40px;
}