#debug {
	background: #292929;
	padding: 20px;
	font-size: 10px;
	font-family: "Open Sans", Helvetica Neue, Arial, sans-serif;
	text-align: left;
	color: #7f7f7f;
	word-wrap: break-word;

	h1{
		border: 1px solid #7f7f7f;
		padding: 5px;
		font-size: 14px;
	}
	p{
		border-bottom: 1px solid #7f7f7f;
		border-right: 1px solid #7f7f7f;
		border-left: 1px solid #7f7f7f;
		padding: 5px;
		margin-bottom: 7px;
		font-size: 12px;
	}
}

.frontend_preview {
	position: fixed;
	color: white;
	z-index: 99999;
	padding: 10px;
	margin: 5px;
	-webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
	background: $default-company-color;
	a {color: white;}
}

#sitepreview{
	display: block;
	position: absolute;
	top:0;
	left:0;
	margin:0;
	padding:4px 6px;
	background-color: #f00;
	color:#fff;
	z-index: 2000;
	text-decoration: none;
	font-size: 14px;
	font-weight:bold;
	border:1px solid #000;
}