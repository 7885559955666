.inner {
  .logo {
    width: 170px;
  }

  padding: 50px 0;
  @include media-breakpoint-up(lg) {
    padding: 100px 0 50px 0;
  }

  .social {
    float: right;

    a {
      font-size: 35px;
      margin-right: 20px;
      @include media-breakpoint-up(lg) {
        font-size: 30px;
        margin-right: 15px;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 25px 50px;
      float: none;
    }
  }

  .navList ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;

      span {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 30px;
        span {
        }
      }
    }
  }

  .phone {
    font-size: 26px;
    margin-bottom: 25px;
  }

  .claim {
    font-size: 22px;
    margin-bottom: 25px;

    .registered {
      font-size: 16px;
      vertical-align: super;
    }
  }

  .space {
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .btn-newsletter {
    float: right;
    @include media-breakpoint-up(sm) {
      float: none;
      margin-left: 20px;
    }
    @include media-breakpoint-up(lg) {
      float: right;
      margin-left: 0;
    }
  }
}

.wave {
  position: absolute;
  top: 0;
  width: 100%;
  height: 45px;
  @include media-breakpoint-up('lg') {
    height: 100px;
  }
}

@media screen and (max-width: 450px) {
  .footer-meta {
    text-align: center;

    .btn {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

@include media-breakpoint-only('lg') {
  .footer-meta {
    .btn {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
