.focuspoint {
  height: 100%;
  width: 100%;
  max-height: none;
  max-width: none;
  overflow: hidden; /*Prevent scrollbars in IE8*/
  margin: 0;
}

.focuspoint {
  position: relative; /*Any position but static should work*/
  overflow: hidden;
}

.focuspoint img {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
}