.slick-slider {
  overflow: hidden;
}

.stageSlider {
  position: relative;

  .wave {
    .item {
      position: absolute;
      z-index: 150;
      width: 100%;
      height: 50px
    }

    &__top {
      img {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
      }

      top: 200px;
      @include media-breakpoint-up(sm) {
        top: 350px;
      }
      @include media-breakpoint-up(lg) {
        top: 350px;
      }
      @include media-breakpoint-up(xl) {
        top: 450px;
      }
    }

    &__bottom {
      img {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
      }

      top: 249px;
      @include media-breakpoint-up(sm) {
        top: 399px;
      }
      @include media-breakpoint-up(lg) {
        top: 399px;
      }
      @include media-breakpoint-up(xl) {
        top: 499px;
      }
    }
  }

  .stageItem {
    position: relative;

    &__Visual {
      z-index: 80;
      height: 250px;
      background-position: center center;
      background-size: cover;
      @include media-breakpoint-up(sm) {
        height: 400px;
      }
      @include media-breakpoint-up(lg) {
        height: 400px;
      }
      @include media-breakpoint-up(xl) {
        height: 500px;
      }
    }

    &__Video {
      position: absolute;
      z-index: 90;
      width: 100%;
      height: 250px;
      display: block;
      margin: 0;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      top: 125px;

      .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;

        iframe,
        object,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: none;
        }
      }

      @include media-breakpoint-up(sm) {
        height: 400px;
        top: 200px;
      }
      @include media-breakpoint-up(xl) {
        width: 880px;
        height: 495px;
        top: 250px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }
    }

    &__Content {
      padding: 20px 0;

      .content {
        display: none;
      }

      .cta {
        text-align: center;
        margin-top: 20px;
      }

      p.date {
        font-size: 16px;
      }

      @include media-breakpoint-up(sm) {
        padding: 25px 0;
        .content {
          display: block;
        }
        .cta {
          text-align: left;
        }
      }
    }
  }

  &__previous,
  &__next {
    position: absolute;
    z-index: 200;
    transform: translateY(-50%);
    margin: 0;
    box-sizing: border-box;
    height: 60px;
    width: 60px;
    line-height: 60px;
    cursor: pointer;
    text-align: center;
    font-size: 30px;
    color: $default-company-complementary-color;
    transition: all 200ms ease;
    opacity: 0.8;
    top: 120px;

    &:hover {
      opacity: 1;
    }

    @include media-breakpoint-up(sm) {
      top: 200px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 40px;
      top: 200px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 60px;
      top: 250px;
    }
  }

  &__previous {
    left: 0;

    &:after {
      font-family: $fa-font-family-light;
      @extend .fal;
      content: "\f053";
    }

    @include media-breakpoint-up(xl) {
      left: 100px;
    }
  }

  &__next {
    right: 0;

    &:after {
      font-family: $fa-font-family-light;
      @extend .fal;
      content: "\f054";
    }

    @include media-breakpoint-up(xl) {
      right: 100px;
    }
  }
}

.slick-dots {
  position: absolute;
  top: 210px;
  z-index: 200;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  @include media-breakpoint-up(sm) {
    top: 360px;
  }
  @include media-breakpoint-up(xl) {
    top: 450px;
  }

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    &:before {
      display: none;
    }

    span.dot {
      border: 0;
      background: transparent;
      display: block;
      height: 15px;
      width: 15px;
      outline: none;
      cursor: pointer;
      background: $white;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      transition: all 200ms ease;
      opacity: 0.5;

      &:hover, &:focus {
        outline: none;
        opacity: 0.8;
      }
    }

    &.slick-active span {
      background: $default-company-color-secondary;
      opacity: 0.8;
    }
  }
}

.ratingSlider {
  position: relative;
  background: $default-company-color;
  color: $default-company-complementary-color;
  padding: 30px 50px;
  border-radius: 10px;
  margin-bottom: 40px;

  .ratingItem {
    text-align: center;

    &__Visual {
      margin-bottom: 30px;

      .img {
        position: relative;
        height: 175px;
        width: 175px;
        margin: 0 auto;

        img {
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
    }

    &__Text {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    &__Credit {
      p {
        font-size: 16px;
        line-height: 18px;
        color: $default-company-complementary-color;
      }
    }
  }

  &__previous,
  &__next {
    position: absolute;
    z-index: 200;
    transform: translateY(-50%);
    margin: 0;
    box-sizing: border-box;
    height: 50px;
    width: 50px;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    color: $default-company-complementary-color;
    transition: all 200ms ease;
    opacity: 0.8;
    top: 50%;
    font-size: 22px;

    &:hover {
      opacity: 1;
    }
  }

  &__previous {
    left: 0;

    &:after {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f053";
    }
  }

  &__next {
    right: 0;

    &:after {
      font-family: $fa-font-family-solid;
      @extend .fas;
      content: "\f054";
    }
  }
}
